<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        เพิ่มรายการซ่อมบำรุง
      </v-toolbar-title>
    </v-toolbar>
    <div class="mx-4 pb-4 box box-2" style="min-height: 100%;">
      <v-card height="100%" style="overflow: auto">
        <v-col
            cols="12"
            md="10"
            lg="10"
            xl="6"
            style="height: 100%"
        >
          <v-form
              ref="form"
              v-model="valid"
          >
            <v-row>
              <v-col cols="4" align-self="center">รถที่ซ่อม</v-col>
              <v-col cols="8">
                <v-select
                    outlined
                    dense
                    height="32px"
                    class="rounded mt-3"
                    hide-details="auto"
                    v-model="vehicle_id"
                    :items="vehicleOption"
                    placeholder="เลือกรถ"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                >
                  <template slot="selection" slot-scope="data">
                    <div style="display: flex;width: 100%;justify-content: space-between">
                      <span>{{ data.item.name }}</span>
                      <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                    </div>
                  </template>
                  <template slot="item" slot-scope="data">
                    <div style="display: flex;width: 100%;justify-content: space-between">
                      <span>{{ data.item.name }}</span>
                      <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" align-self="center">วันที่</v-col>
              <v-col cols="8">
                <DatePicker v-model="date" default-today/>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col align-self="center">
                <span class="mr-2">รายการซ่อม ({{ items.length }})</span>
                <v-icon @click="addItem()" color="success">mdi-plus-circle</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-data-table
                    :headers="itemHeaders"
                    :items="items"
                    disable-sort
                >
                  <template #item.no="{item}">
                    <td>
                      {{ items.indexOf(item) + 1 }}
                    </td>
                  </template>

                  <template #item.part="{ item }">
                    <!--                    <v-text-field v-model="item.part"-->
                    <!--                                  :rules="[rules.required]"-->
                    <!--                                  outlined-->
                    <!--                                  height="32px"-->
                    <!--                                  hide-details="auto"/>-->
                    <v-combobox
                        hide-selected
                        v-model="item.part"
                        :rules="[rules.required]"
                        height="32px"
                        hide-details="auto"
                        :items="part"
                        outlined
                        clearable
                        dense
                    ></v-combobox>
                  </template>

                  <template #item.type="{ item }">
                    <v-select :items="typeOption"
                              v-model="item.type"
                              item-value="name"
                              outlined
                              hide-details
                              dense
                              height="32px"
                              class="rounded"></v-select>
                  </template>

                  <template #item.quantity="{ item }">
                    <v-text-field v-model="item.quantity"
                                  type="number"
                                  outlined
                                  height="32px"
                                  hide-details="auto"/>
                  </template>
                  <template #item.price="{ item }">
                    <v-text-field v-model="item.price"
                                  type="number"
                                  :rules="[rules.required]"
                                  outlined
                                  height="32px"
                                  suffix="บาท"
                                  hide-details="auto"/>
                  </template>
                  <template #item.totalPrice="{ item }">
                    {{ (item.quantity * item.price).toFixed(2) }}
                  </template>

                  <template #item.action="{ item }" class="px-0">
                    <v-icon @click="removeItem(item)" color="error">mdi-close-circle</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-divider/>

            <v-row align="start" class="mt-10">
              <v-col cols="4" align-self="center">หมายเหตุเพิ่มเติม</v-col>
              <v-col cols="8">
                <v-textarea v-model="remark" outlined hide-details height="64">

                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="mt-8">
            <v-btn text>ยกเลิก</v-btn>
            <v-btn @click="saveToDatabase()" depressed color="success">
              <v-icon left>mdi-content-save</v-icon>
              บันทึก
            </v-btn>
          </v-row>
        </v-col>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "@/components/DatePicker";

export default {
  components: {DatePicker},
  name: "CreateMaintenance",
  data() {
    return {
      rules: {
        required: value => value.length > 0 || "ต้องกรอก",
        required_select: value => !!value || "เลือกหมวดสินค้า",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป"
      },
      vehicleOption: [],
      typeOption: ['ซ่อม', 'ซื้อใหม่'],
      vehicle_id: '',
      date: '',
      remark: '',
      items: [{
        part: "",
        type: "ซ่อม",
        quantity: 1,
        price: 0
      }],
      valid: true,
      itemHeaders: [
        {
          text: 'ลำดับที่',
          value: 'no',
          align: 'center',
          width: '80'
        },
        {
          text: 'อะไหล่',
          value: 'part',
          align: 'left',
        },
        {
          text: 'ประเภท',
          value: 'type',
          align: 'center',
          width: '120'
        },
        {
          text: 'จำนวน',
          value: 'quantity',
          align: 'center',
          width: '150'
        },
        {
          text: 'ราคา',
          value: 'price',
          align: 'center',
          width: '180'
        },
        {
          text: 'ราคารวม',
          value: 'totalPrice',
          align: 'center',
          width: '180'
        },
        {
          text: '',
          value: 'action',
          align: 'center',
          class: 'px-0',
          width: '60'
        },
      ],
      part: []

    }
  },
  created() {
    this.getVehicle()
    this.getPart()
  },
  methods: {
    getPart() {
      axios.get('/api/tracks/part/').then(res => {
        // console.log(res.data.data)
        // this.part = res.data.data
        res.data.data.forEach(p => {
          this.part.push(p.name)
        })
        console.log(this.part)
      }).catch(e => {
        console.error(e)
      })
    },
    getVehicle() {
      axios.get('/api/stocks/vehicle/')
          .then(res => {
            this.vehicleOption = res.data.data
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    addItem() {
      this.items.push({
        part: "",
        type: "ซ่อม",
        quantity: 1,
        price: 0
      })
    },
    removeItem(index) {
      if (this.items.length > 1)
        this.items.splice(this.items.indexOf(index), 1)
    },
    async saveToDatabase() {
      await this.$refs.form.validate()
      if (this.valid) {
        this.items.map(item => {
          item.price = parseFloat(item.price)
          item.quantity = parseFloat(item.quantity)
          return item
        })
        let payload = {
          vehicle_id: this.vehicle_id,
          date: this.date,
          remark: this.remark,
          items: this.items
        }
        console.log(payload)
        axios.post('/api/tracks/maintenance/', payload)
            .then(res => {
              console.log(res.data)
              this.$store.state.snackbar = {
                text: 'เพิ่มข้อมูลการซ่อมบำรุงสำเร็จ',
                visible: true,
                color: 'success'
              }
              this.$router.push({path: '/maintenance'})
            })
            .catch(e => {
              this.$store.state.snackbar = {
                text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
                visible: true,
                color: 'error'
              }
            })
      }

    }
  }
}
</script>

<style scoped>

</style>
